.Rectangle {
  width: auto;
  height: 40px;
  margin: 0px 0 0 0px;
  background-color: #ffffff;
  cursor: pointer;
}

.SmallRect {
  width: 62px;
  height: 40px;
  margin: 0px 0 0 0px;
  background-color: #ffffff;
  cursor: pointer;
}

.icon-menu {
  width: 24px;
  height: 24px;
  margin: 8px 12px 0px 20px;
  user-select: none;
}

.selected_menu_text {
  width: auto;
  height: 19px;
  margin-top: 10px;
  margin-left: 0px;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #59616a;
}

.menu_text {
  width: auto;
  height: 19px;
  margin-top: 10px;
  margin-left: 0px;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #969ba1;
}

.CounterRect {
  height: 18px;
  margin: 0px 0 3px 0px;
  padding: 0px 5px 2px 5px;
  border: solid 1px #e7e9ea;
  border-radius: 4px;
  background-color: #787f86;
}

.CounterFont {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
