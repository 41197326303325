.lpRect {
  width: 254px;
  height: 100vh;
  max-height: 100vh;
  min-height: 410px;
  /* overflow: hidden; */
  user-select: none;
  /* float: left; */
  border-left: solid 1px #e7e9ea;
  background-color: white;
  position: relative;
}

.lpSmallRect {
  width: 62px;
  height: 100vh;
  max-height: 100vh;
  min-height: 410px;
  /* overflow: hidden; */
  user-select: none;
  /* float: left; */
  border-left: solid 1px #e7e9ea;
  background-color: white;
  position: relative;
}

.collapseArrow {
  width: 24px;
  height: 24px;
  margin-top: 24px;
  /* margin-left: 37px; */
  transform: scaleX(1);
  object-fit: contain;
  cursor: pointer;
}

.expandArrow {
  width: 24px;
  height: 24px;
  margin-top: 24px;
  margin-left: 20px;
  transform: scaleX(-1);
  object-fit: contain;
  cursor: pointer;
}

.row {
  display: table;
  clear: both;
}

.column {
  float: left;
}

.nav_logo_locale {
  color: #6a6a6a;
  font-weight: 400;
  font-family: 'Source Sans Pro';
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-size: 1rem;
}

.nav_log_locale_div {
  margin-top: 28px;
}
