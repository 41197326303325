.content {
  width: auto;
  display: flex;
  min-height: 100vh;

  /* margin: auto; */
  /* display: grid;
  grid-template-columns: 256px 1184px; */
  /* position: relative; */
  background-color: #eeeff0;

  overflow: hidden;
}

.row {
  display: table;
  clear: both;
}

.column {
  float: left;
}

.dividerLine {
  width: 1px;
  height: 798px;
  margin: 0 0 0 0px;
  background-color: #e7e9ea;
}

.ScrollBox {
  /* position: relative; */
  /* width: 100%; */
  /* height: 100%; */
  overflow-x: hidden;
  overflow-y: auto;
}
